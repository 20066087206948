@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&display=swap');

.text-brand-regular {
    font-family: 'Abhaya Libre', serif;
    font-weight: 400;
    font-style: normal;
}

.text-brand-medium {
    font-family: 'Abhaya Libre', serif;
    font-weight: 500;
    font-style: normal;
}

.text-brand-semibold {
    font-family: 'Abhaya Libre', serif;
    font-weight: 600;
    font-style: normal;
}

.text-brand-bold {
    font-family: 'Abhaya Libre', serif;
    font-weight: 700;
    font-style: normal;
}

.text-brand-extrabold {
    font-family: 'Abhaya Libre', serif;
    font-weight: 800;
    font-style: normal;
}

* {
    user-select: none;
}

.swipe {
    position: absolute;
}
